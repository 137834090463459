<template>
  <!-- <b-col> -->
    <b-row class="bordered">
      <b-col>
        <b-card bg-variant="dark" text-variant="white" 
          :title="`${assessmentTypeName}`">
          <b-card-text>
              {{ assessment.id }}-{{ fullSentence }}
          </b-card-text>
          {{ children }} - {{ tree_level }}
        </b-card>
      </b-col>
      <template v-if="tree_level < 3">
        <b-col>
        <AssessmentCard v-for="child_id in children" 
          :key="child_id"
          :assessment_id="child_id"
          :tree_level="tree_level + 1"
        ></AssessmentCard>
        </b-col>
      </template>
    </b-row>
    <!-- <b-card v-else bg-variant="dark" text-variant="white" 
        :title="`${assessmentTypeName}`">
        <b-card-text>
            {{ assessment.id }}-{{ fullSentence }}
        </b-card-text>
        {{ children }} - {{ tree_level }} -->
        <!-- <b-button href="#" variant="primary">Go somewhere</b-button> -->
    <!-- </b-card> -->
    
  <!-- </b-col> -->
</template>

<script>
export default {
    name: "AssessmentCard",
    components: {},
    props: {
        assessment_id: Number,
        tree_level: Number,
    },
    data() {
        return {
          assessment: {
            id: this.assessment_id,
            full_sentence: "-",
            children: [],
          },
          assessment_types: [],
        };
    },
    computed: {
      assessmentTypeName() {
        const assessmentType = this.assessment_types.find(x => x.id == this.type);
        return assessmentType ? assessmentType.name : '-';
      },
      fullSentence() {
        return this.assessment.full_sentence ? this.assessment.full_sentence : 
        this.assessment.action + ' ' + this.assessment.content + ' ' + this.assessment.condition;
      },
      children() {
        return this.assessment.children.length ? this.assessment.children : null;
      }
    },
    methods: {
      fetchAssessmentTypes() {
        this.$restful
        .Get(`/assessment/assessment-type/`)
        .then((response) => {
          this.assessment_types = response;
        });
      },
      fetchAssessment() {
        this.$restful
        .Get(`/assessment/assessment/${this.assessment_id}/`)
        .then((response) => {
          this.assessment = response;
        });
      }
    },
    created() {
      this.fetchAssessmentTypes();
      this.fetchAssessment();
    },
}
</script>

<style scoped>
.bordered {
  border: 1px rgb(51, 82, 117) solid;
  border-radius: 5px;
}
</style>